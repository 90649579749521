import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginGuard} from './security/login.guard';

const routes: Routes = [{ path: 'login', loadChildren: () => import('./_components/login/login.module').then(m => m.LoginModule) }, 

{ path: '', loadChildren: () => import('./_components/user/user.module').then(m => m.UserModule),canActivate:[LoginGuard] },





























];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
