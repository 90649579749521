import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import {ToastrModule} from 'ngx-toastr'
import {LoginGuard} from './security/login.guard';

//material
import {MatInputModule} from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatMenuModule} from '@angular/material/menu';
import {MatTableModule} from '@angular/material/table';

import {  MatPaginatorModule, MatProgressSpinnerModule,MatSortModule } from "@angular/material";
import {MatAutocompleteModule} from '@angular/material/autocomplete';
@NgModule({
  declarations: [
    AppComponent
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
ToastrModule.forRoot(),

    //material
    MatInputModule,
    MatFormFieldModule,
    MatMenuModule,MatTableModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatAutocompleteModule
  ],
  providers: [{provide: LoginGuard, useClass: LoginGuard }],
  bootstrap: [AppComponent]
})
export class AppModule { }
