import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router,UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  data
  constructor(private router: Router) { }


  canActivate() {
    console.log(localStorage.getItem('login'))
      if (localStorage.getItem('login')) {
          return true;
      }

      this.router.navigate(['/login']);
      return false;
      
  }
  
}
